body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* header {
  background-color: #db021d !important;
} */

button[type="submit"] {
  border: 1px solid rgba(25, 118, 210, 0.5);
  color: rgb(25, 118, 210);
  background-color: transparent;
}

button[type="submit"]:hover {
  text-decoration: none;
  background-color: rgba(25, 118, 210, 0.04);
  border: 1px solid rgb(25, 118, 210);
}
